import React from 'react';
import { Line } from 'react-chartjs-2';
import './Chart.scss';

const Chart = ( props ) => {
    return(
        <div className="p__t">
            <h6 className="production__title">GJENDJA E KREDISË NË  BUJQËSI</h6>
            <p className="chart__rotate__text">VLERA NË € .000</p>
            <p className="chart__rotate__text-optional">VLERA NË € .000</p>
            <section>
                <div className="pt-2 bank-chart-responsive">
                    <Line  
                        data = { props.data }
                        options = { props.options }
                    />
                </div> 
            </section>
        </div>
    )
}

export default Chart;