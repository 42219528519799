import React from 'react';
import { NavLink } from 'react-router-dom';

import './Sidebar.scss';

const sidebar = () => {
    const imgUrl = '../../../assets/img/';
    return (
        <div className="sidebar">
            <div className="sidebar__item">
                <div className="sidebar__img">
                    <img className="sidebar__menu__item__img" src={imgUrl + "prodhimi.png"}  alt="" /> 
                </div>
                <div className="sidebar__link">
                    <NavLink to="/production-peme" activeClassName="act" className="sidebar__menu__item__link">
                        Prodhimi në nivel ferme
                    </NavLink>
                    <ul className="sidebar__submenu">
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/production-peme" >Pemë</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/production-perime" >Perime</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/production-vreshta" >Vreshta</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/production-drithera" >Drithëra</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/production-blegtoria" >Blegtoria</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/production-tjera" >Të tjera</NavLink></li>
                    </ul>
                </div>
            </div>
            <div className="sidebar__item">
                <div className="sidebar__img">
                    <img className="sidebar__menu__item__img" src={imgUrl + "sezonaliteti.png"}  alt=""/>  
                </div>
                <div className="sidebar__link">
                    <NavLink to="/sezonaliteti-peme" activeClassName="act" className="sidebar__menu__item__link">
                        Sezonaliteti
                    </NavLink>
                    <ul className="sidebar__submenu">
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/sezonaliteti-peme">Pemë</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/sezonaliteti-perime">Perime</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/sezonaliteti-vreshta">Vreshta</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/sezonaliteti-drithera">Drithëra</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink className="sidebar__submenu__item__link" to="/sezonaliteti-tjera">Të tjera</NavLink></li>
                    </ul>
                </div>
            </div>
            <div className="sidebar__item">
                <div className="sidebar__img">
                    <img className="sidebar__menu__item__img" src={imgUrl + "tregtia.png"} alt=""/>  
                </div>
                <div className="sidebar__link">
                    <NavLink to="/tregtia" className="sidebar__menu__item__link" >
                        Tregtia
                    </NavLink>
                </div>
            </div>
            <div className="sidebar__item">
                <div className="sidebar__img">
                    <img className="sidebar__menu__item__img" src={imgUrl + "bank.png"} alt=""/>  
                </div>
                <div className="sidebar__link">
                    <NavLink  className="sidebar__menu__item__link" to="/aktiviteti-bankar">
                        Aktiviteti Bankar
                    </NavLink>
                </div>
            </div>
            <div className="sidebar__item">
                <div className="sidebar__img">
                <img className="sidebar__menu__item__img" src={imgUrl + "perfituesit.png"} alt=""/> 
                </div>
                <div className="sidebar__link">
                    <NavLink to="/perfituesit-bujqesor-grantet" activeClassName="act" className="sidebar__menu__item__link">
                        Përfituesit Bujqësor
                    </NavLink>
                    <ul className="sidebar__submenu">
                        <li className="sidebar__submenu__item"><NavLink to="/perfituesit-bujqesor-grantet" className="sidebar__submenu__item__link">Grantet</NavLink></li>
                        <li className="sidebar__submenu__item"><NavLink to="/perfituesit-bujqesor-subvencionet" className="sidebar__submenu__item__link">Subvencionet</NavLink></li>
                    </ul>
                </div>
            </div>
            <div className="sidebar__item">
                <div className="sidebar__img">
                    <img className="sidebar__menu__item__img" src={imgUrl + "info.png"} alt=""/>  
                </div>
                <div className="sidebar__link">
                    <NavLink to="/information" activeClassName="act" className="sidebar__menu__item__link" to="/information">
                        Informacione Shtesë
                    </NavLink>
                    <ul className="sidebar__submenu">
                        <li className="sidebar__submenu__item"><NavLink to="/information" className="sidebar__submenu__item__link">Të dhënat hidrometeorologjike</NavLink></li>
                        {/* <li className="sidebar__submenu__item"><NavLink to="/datasource" className="sidebar__submenu__item__link">Burimi i të dhënave</NavLink></li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default sidebar;