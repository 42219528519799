import React, { Component } from 'react';
import { Navbar , Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './Advertisement.scss';
import Nbar from '../Navbar/Navbar';

class Advertisement extends Component {

    toggleNavItems = () => {
        this.toggle.click();
    } 
    render(){
        const imgUrl = '../../../assets/img/';
        const link = 'https://agroportal-ks.com/';
    return (
        <div className="container logo">
            <div className="row">
                <div className="col-lg-6 text-left pl-0 pt-3 pb-3">
                    <a href={link}><img src={imgUrl+'logo.gif'} alt="" /></a>
                </div>
                <div className="col-lg-6 text-right pr-0 pt-3 pb-3">
                    <Nbar />
                </div>
            </div>
             <div className="row">
                <div className="col-lg-12 p-0 web-nav">
                    <Navbar expand="sm" >
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href={link}>KRYEFAQJA</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'video'}>AGRO PRAKTIKA</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="#">AGRO DATA</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'mundesi-punesimi'}>MUNDËSI PUNËSIMI</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'grante'}>GRANTE</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link"  href={ link + 'agro-oferta'}>AGRO OFERTA</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'standardet-e-cilesise'}>STANDARDET E CILËSISË</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'shoqatat'}>SHOQATAT</a>
                            </li>
                        </Nav>
                        {/* <form className="form-inline my-2 my-lg-0">
                            <div className="search">
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" />
                                <i className="fa fa-search"></i>
                            </div>
                        </form> */}
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
            
            <div className="row">
                <div className="col-lg-12 p-0 mobile-nav">
                    <Navbar expand="xl">
                    <Navbar.Brand></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" ref = { (val) => this.toggle = val} />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href={link}>KRYEFAQJA</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'video'}>AGRO PRAKTIKA</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link activated-nn" href="#">AGRO  DATA</a>
                            </li>
                            <NavDropdown title="Prodhimi në nivel ferme" id="basic-nav-dropdown">
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/production/peme">Pemë</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/production/perime">Perime</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/production/vreshta">Vreshta</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/production/drithera">Drithëra</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/production/blegtoria">Blegtoria</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/production/tjera">Të Tjera</NavLink>
                            </NavDropdown>
                            <NavDropdown title="Sezonaliteti" id="basic-nav-dropdown">
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/sezonaliteti/peme">Pemë</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/sezonaliteti/perime">Perime</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/sezonaliteti/vreshta">Vreshta</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/sezonaliteti/drithera">Drithëra</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/sezonaliteti/tjera">Te Tjera</NavLink>
                            </NavDropdown>
                            <li className="nav-item">
                                <NavLink to="/tregtia" className="nav-link" onClick = { this.toggleNavItems }>
                                    Tregtia
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/aktiviteti-bankar" className="nav-link" onClick = { this.toggleNavItems }>
                                    Aktiviteti Bankar
                                </NavLink>
                            </li>
                            <NavDropdown title="Perfituesit Bujqesor" id="basic-nav-dropdown">
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/perfituesit-bujqesor/grantet">Grantet</NavLink>
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/perfituesit-bujqesor/subvencionet">Subvencione</NavLink>
                            </NavDropdown>
                            <NavDropdown title="Informacione Shtese" id="basic-nav-dropdown">
                                <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/information">Te Dhënat Hidrometeorologjike</NavLink>
                                {/* <NavLink className="dropdown-item t" onClick = { this.toggleNavItems } to="/datasource">Burimi i te Dhënave</NavLink> */}
                            </NavDropdown>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'mundesi-punesimi'}>MUNDËSI PUNËSIMI</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'grante'}>GRANTE</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href={ link + 'agro-oferta'}>AGRO OFERTA</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'standardet-e-cilesise'}>STANDARDET E CILËSISË</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href={ link + 'shoqatat'}>SHOQATAT</a>
                            </li>
                        </Nav>
                        {/* <form className="form-inline my-2 my-lg-0">
                            <div className="search">
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" />
                                <i className="fa fa-search"></i>
                            </div>
                        </form> */}
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>       
        </div>
        )
    }
};

export default Advertisement;