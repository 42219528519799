import React from 'react';
import { Bar } from 'react-chartjs-2';
import './Chart.scss';

const Chart = (props) => {
    return(
        <div className="benefit-chart-responsive">
            <Bar 
                height = { props.resize ? 300 : null }
                data = { props.data }
                options = { props.options }
            />
        </div>
    )
}

export default Chart;
