import React, { Component } from 'react';

import './Bank.scss';
import Filter from './Filter/Filter';
import Chart from './Chart/Chart';
import Interes from './Interes/Interes';
import Axios from 'axios';

class Bank extends Component {
    state = {
        activities:[{name:'Banka', key:'banka'},{name:'Mikrofinanca', key:'mikrofinanca'}],
        activity: 'banka',
        years:[],
        selectedYear: null,
        loans:['Kreditë në bujqësi - deri në 1 vit','Kreditë në bujqësi - mbi 1 vit','Gjithësej Kredite ne Bujqësi'],
        chart1:{
            data:{
                labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ", "QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NËNTOR","DHJETOR"],
                datasets: [
                    {
                        hidden: false,
                        label: 'Kredite në bujqesi - deri në 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#8cc65d',
                        ],
                        borderWidth: 2
                    },
                    {
                        hidden: false,
                        label: 'Kreditë në bujqësi - mbi 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#0081c6',
                        ],
                        borderWidth: 2
                    },    
                    {
                        hidden: false,
                        label: 'Totali',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            'black',
                        ],
                        borderWidth: 2
                    }    
                ]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    position: 'bottom',
                    onClick: (e) => e.stopPropagation(),
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
            
        },
        charts:[
            {
                data: {
                    labels: ["Deri në 1 vit"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["1-2 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["2-5 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["5-10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]
                }
            },
            {
                data: {
                    labels: ["Mbi 10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            }
        ],
        options: {
            tooltips: {enabled: false},
            hover: {mode: null},
            responsive: true,
            maintainAspectRatio: true,
            cutoutPercentage: 88,
            scales: {
                yAxes: [{
                    ticks: {
                        drawOnChartArea: false,
                        beginAtZero:true,
                        display: false
                    },
                    gridLines: {
                        // // color: "rgba(0, 0, 0, 0)",
                        // display: false,
                        drawOnChartArea: false,
                        drawBorder: false,
                      
                    }
                }]
            },
            legend: {
                onClick: (e) => e.stopPropagation(),
                display: true,
                labels:{fontSize:18}
            },
        }
    }

    componentDidMount(){

        //year generation
        let years = [...this.state.years ];
        let startYear = new Date().getFullYear() ;
        for(let i = 4; i >= 0; i--){
            years.unshift( startYear - i);
        }
        this.setState({ years: years, selectedYear: startYear });

        //chart object for interes
        let chart1 = {
            data:{
                labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ", "QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NENTOR","DHJETOR"],
                datasets: [
                    {
                        hidden: false,
                        label: 'Kreditë në bujqësi - deri në 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#8cc65d',
                        ],
                        borderWidth: 2
                    },
                    {
                        hidden: false,
                        label: 'Kreditë në bujqësi - mbi 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#0081c6',
                        ],
                        borderWidth: 2
                    },    
                    {
                        hidden: false,
                        label: 'Totali',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            'black',
                        ],
                        borderWidth: 2
                    }    
                ]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    position: 'bottom',
                    onClick: (e) => e.stopPropagation()
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
            
        }

        let circlechart = [
            {
                data: {
                    labels: ["Deri në 1 vit"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["1-2 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["2-5 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["5-10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["Mbi 10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            }
        ];

        Axios.get('https://www.agrodata-ks.com/api/api/bank/filter?', {
            params:{
                "type": "banka",
                "year": startYear
            }
        }).then((response) => { 
            circlechart[0].data.datasets[0].data = [response.data.interestRateToAYear, 100-response.data.interestRateToAYear ];
            circlechart[1].data.datasets[0].data = [response.data.interestRateOneToTwoYears, 100-response.data.interestRateOneToTwoYears ];
            circlechart[2].data.datasets[0].data = [response.data.interestRateTwoToFiveYears, 100-response.data.interestRateTwoToFiveYears ];
            circlechart[3].data.datasets[0].data = [response.data.interestRateFiveToTenYears, 100-response.data.interestRateFiveToTenYears ];
            circlechart[4].data.datasets[0].data = [response.data.interestRateAboveTenYears, 100-response.data.interestRateAboveTenYears ];

            for( let j = 1; j <= 12; j++ ){
                chart1.data.datasets[0].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].valueToAYear !== null ? parseFloat(response.data.chartData[j].valueToAYear.toFixed(2)) : 0;
                chart1.data.datasets[1].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].valueAboveAYear !== null ? parseFloat(response.data.chartData[j].valueAboveAYear.toFixed(2)) : 0;
                chart1.data.datasets[2].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].totalValue !== null ? parseFloat(response.data.chartData[j].totalValue.toFixed(2)) : 0;
            }
            

            this.setState({ charts: circlechart, chart1: chart1 })
        });
    }

    changeYear = ( year ) => {
        this.setState({ selectedYear: year });

        let chart1 = {
            data:{
                labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ", "QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NENTOR","DHJETOR"],
                datasets: [
                    {
                        hidden:  this.state.chart1.data.datasets[0].hidden,
                        label: 'Kreditë në bujqësi - deri në 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#8cc65d',
                        ],
                        borderWidth: 2
                    },
                    {
                        hidden:  this.state.chart1.data.datasets[1].hidden,
                        label: 'Kreditë në bujqësi - mbi 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#0081c6',
                        ],
                        borderWidth: 2
                    },    
                    {
                        hidden:  this.state.chart1.data.datasets[2].hidden,
                        label: 'Totali',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            'black',
                        ],
                        borderWidth: 2
                    }    
                ]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    
                    position: 'bottom',
                    onClick: (e) => e.stopPropagation(),
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
            
        }

        let circlechart = [
            {
                data: {
                    labels: ["Deri në 1 vit"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["1-2 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["2-5 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["5-10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["Mbi 10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            }
        ];

        Axios.get('https://www.agrodata-ks.com/api/api/bank/filter?', {
            params:{
                "type": this.state.activity,
                "year": year
            }
        }).then((response) => { 

            if(response.data.year ) {
                circlechart[0].data.datasets[0].data = [response.data.interestRateToAYear, 100-response.data.interestRateToAYear ];
                circlechart[1].data.datasets[0].data = [response.data.interestRateOneToTwoYears, 100-response.data.interestRateOneToTwoYears ];
                circlechart[2].data.datasets[0].data = [response.data.interestRateTwoToFiveYears, 100-response.data.interestRateTwoToFiveYears ];
                circlechart[3].data.datasets[0].data = [response.data.interestRateFiveToTenYears, 100-response.data.interestRateFiveToTenYears ];
                circlechart[4].data.datasets[0].data = [response.data.interestRateAboveTenYears, 100-response.data.interestRateAboveTenYears ];

                for( let j = 1; j <= 12; j++ ){
                    chart1.data.datasets[0].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].valueToAYear !== null ? parseFloat(response.data.chartData[j].valueToAYear.toFixed(2)) : 0;
                    chart1.data.datasets[1].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].valueAboveAYear !== null ? parseFloat(response.data.chartData[j].valueAboveAYear.toFixed(2)) : 0;
                    chart1.data.datasets[2].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].totalValue !== null ? parseFloat(response.data.chartData[j].totalValue.toFixed(2)) : 0;
                }
            
                this.setState({ charts: circlechart, chart1: chart1 });
            }
            else
            {
                circlechart[0].data.datasets[0].data = ['','' ];
                circlechart[1].data.datasets[0].data = ['','' ];
                circlechart[2].data.datasets[0].data = ['','' ];
                circlechart[3].data.datasets[0].data = ['','' ];
                circlechart[4].data.datasets[0].data = ['','' ];

                for( let j = 1; j <= 12; j++ ){
                    chart1.data.datasets[0].data[ j-1 ] = '';
                    chart1.data.datasets[1].data[ j-1 ] = '';
                    chart1.data.datasets[2].data[ j-1 ] = '';
                }
                

                this.setState({ charts: circlechart, chart1: chart1 })
            }
        });
    }

    changeActivity = ( activity ) => {

        this.setState({ activity: activity })

        let chart1 = {
            data:{
                labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ", "QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NËNTOR","DHJETOR"],
                datasets: [
                    {
                        hidden:  this.state.chart1.data.datasets[0].hidden,
                        label: 'Kreditë në bujqësi - deri në 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#8cc65d',
                        ],
                        borderWidth: 2
                    },
                    {
                        hidden:  this.state.chart1.data.datasets[1].hidden,
                        label: 'Kreditë në bujqësi - mbi 1 vit',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            '#0081c6',
                        ],
                        borderWidth: 2
                    },    
                    {
                        hidden:  this.state.chart1.data.datasets[2].hidden,
                        label: 'Totali',
                        data: [],
                        backgroundColor: [
                            'transparent'
                        ],
                        borderColor: [
                            'black',
                        ],
                        borderWidth: 2
                    }    
                ]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    position: 'bottom',
                    onClick: (e) => e.stopPropagation()
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero:true
                        }
                    }]
                }
            }
            
        }

        let circlechart = [
            {
                data: {
                    labels: ["Deri në 1 vit"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["1-2 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["2-5 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["5-10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            },
            {
                data: {
                    labels: ["Mbi 10 vite"],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#0081c6',
                                '#f1f1f1'
                            ]
                        }
                    ]  
                }
            }
        ];

        Axios.get('https://www.agrodata-ks.com/api/api/bank/filter?', {
            params:{
                "type": activity,
                "year": this.state.selectedYear
            }
        }).then((response) => { 

            if(response.data.year){
                circlechart[0].data.datasets[0].data = [response.data.interestRateToAYear, 100-response.data.interestRateToAYear ];
                circlechart[1].data.datasets[0].data = [response.data.interestRateOneToTwoYears, 100-response.data.interestRateOneToTwoYears ];
                circlechart[2].data.datasets[0].data = [response.data.interestRateTwoToFiveYears, 100-response.data.interestRateTwoToFiveYears ];
                circlechart[3].data.datasets[0].data = [response.data.interestRateFiveToTenYears, 100-response.data.interestRateFiveToTenYears ];
                circlechart[4].data.datasets[0].data = [response.data.interestRateAboveTenYears, 100-response.data.interestRateAboveTenYears ];

                for( let j = 1; j <= 12; j++ ){
                    chart1.data.datasets[0].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].valueToAYear !== null ? parseFloat(response.data.chartData[j].valueToAYear.toFixed(2)) : 0;
                    chart1.data.datasets[1].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].valueAboveAYear !== null ? parseFloat(response.data.chartData[j].valueAboveAYear.toFixed(2)) : 0;
                    chart1.data.datasets[2].data[ j-1 ] = response.data.chartData[j] && response.data.chartData[j].totalValue !== null ? parseFloat(response.data.chartData[j].totalValue.toFixed(2)) : 0;
                }
                

                this.setState({ charts: circlechart, chart1: chart1 })
            }
            else
            {
                circlechart[0].data.datasets[0].data = ['','' ];
                circlechart[1].data.datasets[0].data = ['','' ];
                circlechart[2].data.datasets[0].data = ['','' ];
                circlechart[3].data.datasets[0].data = ['','' ];
                circlechart[4].data.datasets[0].data = ['','' ];

                for( let j = 1; j <= 12; j++ ){
                    chart1.data.datasets[0].data[ j-1 ] = '';
                    chart1.data.datasets[1].data[ j-1 ] = '';
                    chart1.data.datasets[2].data[ j-1 ] = '';
                }
                

                this.setState({ charts: circlechart, chart1: chart1 })
            }
        });
    }

    //this toggles legend simultaneously with 'Lloji i kredise';
    legendClick = (index) => {
        this.state.chart1.data.datasets[index].hidden =  !this.state.chart1.data.datasets[index].hidden;
        this.setState({});
    }

    render() {
        return (
            <div className="col-lg-12 pages__content">
                <div className="row">
                    <div className="col-lg-4">
                        <h5 className="production__title">AKTIVITETI BANKAR</h5>
                            <Filter 
                                activities = { this.state.activities }
                                years = { this.state.years }
                                loans = { this.state.loans }
                                click = { this.changeYear }
                                changeActivity = { this.changeActivity }
                                selectedYear = { this.state.selectedYear }
                                activity = { this.state.activity }
                                legendClick = { this.legendClick }
                            />  
                    </div>
                    <div className="col-lg-8 p__t">
                        <Chart 
                            data = { this.state.chart1.data} 
                            options = { this.state.chart1.options }
                            resize = { this.props.resize }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 p__t">
                        <p className="circle__chart__title">Norma Mesatare e Interesit, {this.state.selectedYear}</p>
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.charts.map((chart, index) => {
                            return (
                                    <Interes 
                                        key={index} 
                                        data = { chart.data }
                                        options = { this.state.options}
                                        resize = { this.props.resize }
                                    />
                                )
                        })
                    }
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="alert alert-success mb-5 mt-5" role="alert" style={{display: "table", fontSize: "14px"}}>
                            <p className="m-0">
                                <strong>Burimi i të dhënave: </strong>Banka Qendrore e Republikës së Kosovës (BQK)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Bank;