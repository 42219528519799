import React from 'react';
import './SeasonalityRows.scss';
import Aux from '../../Hoc';

const SeasonalityRows = (props) => {
    const imgUrl = '../../../assets/img/';
    var color = props.colorClass % 2 == 0 ? 'green' : 'orange';
    var rowClass = `bodyRows ${color}Background`;
    var colClass = `column--${color} activity`;
    return (
        <Aux>
            <tr className={rowClass} key={Math.random()*Math.random()}>
                <td className={colClass}>{props.value}</td>
                {props.images.map((value, index) => {
                    return (value !== '') ? <td key={index}><img src={imgUrl + (props.colorClass % 2 == 0 ? 'green-' : 'orange-') + value} alt=""/></td> : <td key={index}></td>
                })}
            </tr>
        </Aux>
    );
};

export default SeasonalityRows;