import React from 'react';
import './Interes.scss';

import { Doughnut } from 'react-chartjs-2';

const Interes = ( props ) => {
    return(
            <div className="col-xl-2 col-lg-4 col-md-6 p__t doughnout">
                <Doughnut 
                    width={ 150 } 
                    data = { props.data } 
                    options = { props.options }
                />
                <p className="doughnout__chart__text text-center">{ props.data.datasets[0].data[0] ? props.data.datasets[0].data[0].toFixed(2): null}%</p>
            </div>
    )
}

export default Interes;