import React from 'react';
import './Filter.scss';

const Filter = (props) => {
    return(
       <div>
            <div className="culture__filter">
                <label className="title">{props.filterTitle}</label>
                <select id="culture__filter" onChange={ (event) => { props.getUpdateData(event)} }>
                    {
                        props.parameters.map((value, index) => {
                            return <option key={value._id} value={value._id}>{value.name}</option>
                        })
                    }
                </select>
            </div>
            <div className="general__filter">
                <label className="title">Viti</label>
                <div className="radiobuttons">
                    <form>
                        <ul className="row ul">
                            {props.years.map((value, index) => {
                                return <li key={index} className="col-lg-12 col-6"><input id={'grant__filter' + index } defaultChecked={index == 0 ? "true" : ""} type="radio" name="year" className="input filter__block__radiobuttons__input" onClick = { () => { props.selectedYear(value)}}/><label htmlFor={'grant__filter' + index }>{ value }</label></li>;
                            })}
                        </ul>
                    </form>
                </div>
            </div>
       </div>  
    )
}

export default Filter;