import React, { Component } from 'react';
import SeasonalityRows from './SeasonalityRows/SeasonalityRows';
import './Seasonality.scss';
import Aux from '../Hoc';
import Axios from 'axios';

class Seasonality extends Component {
    state = {
        elements: []
    };

    componentDidMount() {
        Axios.get('https://www.agrodata-ks.com/api/api/season/category', {
            params: {
                "category": this.props.category
            }
        }).then(data => {
            this.setState({
                elements: data.data
            });
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12 pages__content seasonality">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <h5 className="title">{this.props.title}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tableContainer t0">
                                    <table>
                                        <thead>
                                            <tr className="headRows">
                                                <th className="culture">KULTURA</th>
                                                <th className="activity">AKTIVITETI</th>
                                                <th className="months" colSpan="2">JANAR</th>
                                                <th className="months" colSpan="2">SHKURT</th>
                                                <th className="months" colSpan="2">MARS</th>
                                                <th className="months" colSpan="2">PRILL</th>
                                                <th className="months" colSpan="2">MAJ</th>
                                                <th className="months" colSpan="2">QERSHOR</th>
                                                <th className="months" colSpan="2">KORRIK</th>
                                                <th className="months" colSpan="2">GUSHT</th>
                                                <th className="months" colSpan="2">SHTATOR</th>
                                                <th className="months" colSpan="2">TETOR</th>
                                                <th className="months" colSpan="2">NËNTOR</th>
                                                <th className="months" colSpan="2">DHJETOR</th>
                                            </tr>
                                        </thead>
                                        <tbody className="body">
                                        {
                                            this.state.elements.map((value, outIndex) => {
                                                var color = `${outIndex % 2 == 0 ? 'green' : 'orange'}--cultureName cultureName`;
                                                return (
                                                    <Aux key={outIndex}>
                                                        <tr className={color}>
                                                            <td rowSpan={value.activity.length+1}><p>{value.culture}</p></td>
                                                        </tr>
                                                        {
                                                            value.activity.map((row, index) => {
                                                                return <SeasonalityRows propsKey={outIndex + index + Math.random()} key={index} colorClass={outIndex} value={ row.name } images={ row.images }/>
                                                            })
                                                        }
                                                    </Aux>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tableContainer t1">
                                    <table>
                                        <thead>
                                            <tr className="headRows">
                                                <th className="culture">KULTURA</th>
                                                <th className="activity">AKTIVITETI</th>
                                                <th className="months" colSpan="2">JANAR</th>
                                                <th className="months" colSpan="2">SHKURT</th>
                                                <th className="months" colSpan="2">MARS</th>
                                                <th className="months" colSpan="2">PRILL</th>
                                                <th className="months" colSpan="2">MAJ</th>
                                                <th className="months" colSpan="2">QERSHOR</th>
                                                <th className="months" colSpan="2">KORRIK</th>
                                                <th className="months" colSpan="2">GUSHT</th>
                                                <th className="months" colSpan="2">SHTATOR</th>
                                                <th className="months" colSpan="2">TETOR</th>
                                                <th className="months" colSpan="2">NËNTOR</th>
                                                <th className="months" colSpan="2">DHJETOR</th>
                                            </tr>
                                        </thead>
                                        <tbody className="body">
                                        {
                                            this.state.elements.map((value, outIndex) => {
                                                var color = `${outIndex % 2 == 0 ? 'green' : 'orange'}--cultureName cultureName`;
                                                return (
                                                    <Aux key={outIndex}>
                                                        <tr className={color}>
                                                            <td rowSpan={value.activity.length+1}><p>{value.culture}</p></td>
                                                        </tr>
                                                        {
                                                            value.activity.map((row, index) => {
                                                                return <SeasonalityRows propsKey={outIndex + index + Math.random()} key={index} colorClass={outIndex} value={ row.name } images={ row.images }/>
                                                            })
                                                        }
                                                    </Aux>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="alert alert-success mb-5 mt-5" role="alert" style={{display: "table", fontSize: "14px"}}>
                                    <p className="m-0">
                                        <strong>Burimi i të dhënave: </strong>Katalogu Ekonomik për Prodhime Bujqësore 2018, Ministria e Bujqësisë, Pylltarisë dhe Zhvillimit Rural (MBPZHR)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seasonality;