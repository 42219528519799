import React from 'react';
import { Bar } from 'react-chartjs-2';
import './Chart.scss';

const Chart = (props) => {
    return(
       <div className="weather-chart-responsive">
           <Bar 
                // width = { 100 }
                data = { props.data }
                options = { props.options }
            />
       </div>
    )
}

export default Chart;
