import React from 'react';
import { Bar } from 'react-chartjs-2';
import './Chart.scss';

const Chart = (props) => {
    return(
        <div className="chart chart-test">
            <h6 className="tregtia__filter__title">Eksporti dhe Importi i produkteve bujqësore</h6>
            <p className="chart__rotate__text">VLERA NË € .000</p>
            <p className="chart__rotate__text-optional">VLERA NË € .000</p>
            <section>
                <div className="pt-2 bank-chart-responsive">
                    <Bar 
                        data={props.data}
                        options = { props.options }
                    />
                </div> 
            </section>
        </div>
    )
}

export default Chart;