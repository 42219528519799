import React, { Component } from 'react';
import './Dashboard.scss';
import Axios from 'axios';

class Dashboard extends Component {
    state = {
        username: null,
        password: null,
        auth: false,
        prodhimi: null,
        press: false,
        noData: false,
        wrongData: false,
        descendTable: false,
        production: null,
        uploadingProduction: false,
        uploadingProductionData: false,
        bank: null,
        uploadingBank: false,
        uploadingBankData: false,
        weather: null,
        uploadingHidrometeorology: false,
        uploadingHidrometeorologyMessage: false,
        seasonality: null,
        uploadingSeasonality: false,
        uploadingSeasonalityData: false,
        trade: null,
        uploadingTrade: false,
        uploadingTradeData: false,
        benefits: null,
        uploadingBenefits: false,
        uploadingBenefitsData: false,
        errorBox: {
            show: false,
            data: [],
            keys: []
        }
    }

    //authentication

    // keyPress = (e) => {
    //     if(e.key === 'Enter'){
    //         if(this.state.username && this.state.password && this.state.username.length > 0 && this.state.password.length > 0){
    //             this.setState({ press: true });
    //             setTimeout( () => { this.setState({ auth:true, press: false, wrongData: true, noData: false }) },1500);
    //         }
    //         else
    //         {
    //             this.setState({ press: true });
    //             setTimeout( () => { this.setState({ press: false, wrongData: false, noData: true }) },1500);
    //         }
    //     }
    // }

    componentDidMount() {
        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    this.setState({ auth: true });
                }
            });
    }

    username = (val) => {
        this.setState({ username: val.target.value });
    }

    password = (val) => {
        this.setState({ password: val.target.value });
    }

    login = () => {
        const user = {
            username: this.state.username,
            password: this.state.password
        }

        if (user.username && user.password && user.username.length > 0 && user.password.length > 0) {
            Axios.post('https://www.agrodata-ks.com/api/user/login', user)
                .then((res) => {
                    if (res.data.NotValid) {
                        this.setState({ wrongData: true, auth: false, noData: false });
                        localStorage.removeItem('token');
                    }
                    else if (res.data.error) {
                        this.setState({ wrongData: true, auth: false, noData: false });
                        localStorage.removeItem('token');
                    }
                    else if (res.data.token) {
                        this.setState({ auth: true, wrongData: true, noData: false, username: null, password: null });
                        localStorage.setItem('token', res.data.token);
                    }
                })
                .catch(err => { });
        }
        else {
            this.setState({ auth: false, wrongData: false, noData: true })
        }
    }


    logOut = () => {
        localStorage.removeItem('token');
        this.setState({ auth: false })
    }
    //uploading

    selectWeather = file => {
        const f = file.target.files[0];
        this.setState({ weather: f });
    }

    selectTrade = file => {
        this.setState({ trade: file.target.files[0] });
    };

    selectProduction = file => {
        const f = file.target.files[0];
        this.setState({ production: f });
    }


    selectBank = file => {
        const f = file.target.files[0];
        this.setState({ bank: f });
    }

    selectSeasonality = file => {
        const f = file.target.files[0];
        this.setState({ seasonality: f });
    }

    selectBenefits = file => {
        this.setState({ benefits: file.target.files[0] });
    };

    uploadWeather = () => {

        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    this.setState({ uploadingHidrometeorology: true });
                    const fd = new FormData();
                    fd.append('file', this.state.weather);
                    Axios.post('https://www.agrodata-ks.com/api/api/dashboard/weather/', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                                this.setState({ uploadingHidrometeorologyMessage: true });
                                setTimeout(() => {
                                    this.setState({ uploadingHidrometeorologyMessage: false, uploadingHidrometeorology: false, weather: null });
                                }, 3500)
                            }
                            else {
                                const errorBox = Object.assign({}, this.state.errorBox);
                                errorBox.data = res.data.data;
                                errorBox.show = true;
                                const keys = Object.keys(res.data.data[0].data);
                                errorBox.keys = keys;
                                this.setState({ errorBox: errorBox });
                            }
                        });
                }
            });

    };

    uploadProduction = () => {
        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    this.setState({ uploadingProduction: true });
                    const fd = new FormData();
                    fd.append('file', this.state.production);
                    Axios.post('https://www.agrodata-ks.com/api/api/dashboard/production/', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                                this.setState({ uploadingProductionData: true });
                                setTimeout(() => {
                                    this.setState({ uploadingProductionData: false, uploadingProduction: false, production: null });
                                }, 3500)
                            }
                            else {
                                const errorBox = Object.assign({}, this.state.errorBox);
                                errorBox.data = res.data.data;
                                errorBox.show = true;
                                const keys = Object.keys(res.data.data[0].data);
                                errorBox.keys = keys;
                                this.setState({ errorBox: errorBox });
                            }
                        }).catch(err => this.setState({ uploadingProductionData: false, uploadingProduction: false, production: null }))
                }
            });

    };

    uploadSeasonality = () => {
        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    this.setState({ uploadingSeasonality: true });
                    const fd = new FormData();
                    fd.append('file', this.state.seasonality);
                    Axios.post('https://www.agrodata-ks.com/api/api/dashboard/seasonality/', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                                this.setState({ uploadingSeasonalityData: true });
                                setTimeout(() => {
                                    this.setState({ uploadingSeasonalityData: false, uploadingSeasonality: false, seasonality: null });
                                }, 3500);
                            }
                            else {
                                const errorBox = Object.assign({}, this.state.errorBox);
                                errorBox.data = res.data.data;
                                errorBox.show = true;
                                const keys = Object.keys(res.data.data[0].data);
                                errorBox.keys = keys;
                                this.setState({ errorBox: errorBox });

                            }
                        }, 3500).catch(err => this.setState({ uploadingSeasonalityData: false, uploadingSeasonality: false, seasonality: null }))
                };
            });
    };

    uploadBank = () => {
        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    const fd = new FormData();
                    this.setState({ uploadingBank: true });
                    fd.append('file', this.state.bank);
                    Axios.post('https://www.agrodata-ks.com/api/api/dashboard/bank/', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                                this.setState({ uploadingBankData: true });
                                setTimeout(() => {
                                    this.setState({ uploadingBankData: false, uploadingBank: false, bank: null });
                                }, 3500);
                            }
                            else {
                                const errorBox = Object.assign({}, this.state.errorBox);
                                errorBox.data = res.data.data;
                                errorBox.show = true;
                                const keys = Object.keys(res.data.data[0].data);
                                errorBox.keys = keys;
                                this.setState({ errorBox: errorBox });

                            }
                        }, 3500).catch(err => this.setState({ uploadingBankData: false, uploadingBank: false, bank: null }))
                };
            });
    };

    uploadTrade = () => {
        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    this.setState({ uploadingTrade: true });
                    const fd = new FormData();
                    fd.append('file', this.state.trade);
                    Axios.post('https://www.agrodata-ks.com/api/api/dashboard/trade/', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                                this.setState({ uploadingTradeData: true });
                                setTimeout(() => {
                                    this.setState({ uploadingTradeData: false, uploadingTrade: false, trade: null });
                                }, 3500);
                            }
                            else {
                                const errorBox = Object.assign({}, this.state.errorBox);
                                errorBox.data = res.data.data;
                                errorBox.show = true;
                                const keys = Object.keys(res.data.data[0].data);
                                errorBox.keys = keys;
                                this.setState({ errorBox: errorBox });

                            }
                        }, 3500).catch(err => this.setState({ uploadingTradeData: false, uploadingTrade: false, trade: null }))
                };
            });
    };

    uploadBenefits = () => {
        let tok = localStorage.getItem('token');
        Axios.defaults.headers.common['Authorization'] = "Bearer " + tok;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.post('https://www.agrodata-ks.com/api/api/verify')
            .then(res => {
                if (res.data.error) {
                    localStorage.removeItem('token');
                    this.setState({ auth: false });
                }
                else if (res.data.success) {
                    this.setState({ uploadingBenefits: true });
                    const fd = new FormData();
                    fd.append('file', this.state.benefits);
                    Axios.post('https://www.agrodata-ks.com/api/api/dashboard/benefits/', fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then((res) => {
                            if (res.data.success) {
                                this.setState({ uploadingBenefitsData: true });
                                setTimeout(() => {
                                    this.setState({ uploadingBenefitsData: false, uploadingBenefits: false, benefits: null });
                                }, 3500);
                            }
                            else {
                                const errorBox = Object.assign({}, this.state.errorBox);
                                errorBox.data = res.data.data;
                                errorBox.show = true;
                                const keys = Object.keys(res.data.data[0].data);
                                errorBox.keys = keys;
                                this.setState({ errorBox: errorBox });

                            }
                        }, 3500).catch(err => this.setState({ uploadingBenefitsData: false, uploadingBenefits: false, benefits: null }))

                };
            });
    };

    render() {
        return (
            <div className="col-lg-12 pages__content production dsh">
                {!this.state.auth ?
                    <div className="backdrop">
                        <div className="login">
                            <div className="login-inputs">
                                <h5>KYQU</h5>
                                <hr />
                                <p></p>
                                {this.state.wrongData ? <p className="m-0" style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '200px' }}>Te Dhenat Jane Gabim!!!</p> : null}
                                {this.state.noData ? <p className="m-0" style={{ color: 'red', fontWeight: 'bold', position: 'absolute', top: '200px' }}>Te Gjitha Fushat Duhet Te Plotesohen!!!</p> : null}
                                <input className="input" type="text" onKeyPress={this.keyPress} placeholder="Përdoruesi" onChange={(event) => { this.username(event) }} />
                                <input className="input" type="password" onKeyPress={this.keyPress} placeholder="Fjalëkalimi" onChange={(event) => { this.password(event) }} />
                            </div>
                            <div className="login-button">
                                <button className="btn btn-primary" onClick={this.login} disabled={this.state.press ? true : false}>{this.state.press ? 'Duke u procesuar...' : 'KYQU'} </button>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {this.state.auth ?
                    <div className="row dashboard">
                        <div className="col-lg-12">
                            {
                                this.state.errorBox.show ?
                                    <div className="error-box-backdrop">
                                        <div className="error-box">
                                            <p>Gabimet në EXCEL ({this.state.errorBox.data.length})</p>
                                            <a className="btn btn-danger mb-3 btn-sm" href="/dashboard-934-tesla-1">Mbyll</a>
                                            <div className="table-content">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Rreshti</th>
                                                            {
                                                                this.state.errorBox.keys.map((key) => {
                                                                    return (
                                                                        <th key={key}> {key} </th>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.errorBox.data.map((data, index) => {
                                                            const objectKeys = Object.keys(data.data);
                                                            return (
                                                                <tr key={index}>
                                                                    <td key={index}> {data.row} </td>
                                                                    {objectKeys.map((s, inx) => {
                                                                        return <td key={inx}>{data.data[s]}</td>
                                                                    })}
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="alert alert-primary" role="alert">
                                        <button className="btn btn-danger" onClick={this.logOut}>Log Out</button>
                                    </div>
                                    <div className="alert alert-primary" role="alert">
                                        <p className="m-0"><strong>AGRO DATA</strong>: Ngarkimi i të Dhënave</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 p-2">
                                    <div className="card card-body">
                                        <p className="login-title-uploads" >Prodhimi Në Nivel Ferme</p>
                                        <input type="file" onChange={(value) => { this.selectProduction(value) }} />
                                        <hr />
                                        <button className="btn btn-primary mb-2" onClick={this.uploadProduction} disabled={this.state.production ? false : true}>Ngarko</button>
                                        <a className="btn btn-primary" href='https://agrodata-ks.com/xlsamples/Prodhimi_ne_nivel_ferme.xlsx' target='_blank'>Shkarko Shembullin</a>



                                        {/* uploading prodhimi ne nivel ferme spinner */}

                                        {this.state.uploadingProduction ?
                                            <div className="uploadingHidrometeorology">
                                                {!this.state.uploadingProductionData ?
                                                    <div>
                                                        <div className="spinner-border" role="status">

                                                        </div>
                                                        <small>Duke Ngarkuar 'Prodhimi Në Nivel Ferme '</small>
                                                    </div>
                                                    :
                                                    <p className="uploaded-successfuly">U NGARKUA ME SUKSES <i className="fas fa-check"></i> </p>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 p-2">
                                    <div className="card card-body">
                                        <p className="login-title-uploads">Sezonaliteti</p>
                                        <input type="file" onChange={(value) => { this.selectSeasonality(value) }} />
                                        <hr />
                                        <button className="btn btn-primary mb-2" onClick={this.uploadSeasonality} disabled={this.state.seasonality ? false : true}>Ngarko</button>
                                        <a className="btn btn-primary" href='https://agrodata-ks.com/xlsamples/Sezonaliteti.xlsx' target='_blank'>Shkarko Shembullin</a>


                                        {/* uploading sezonaliteti spinner */}

                                        {this.state.uploadingSeasonality ?
                                            <div className="uploadingHidrometeorology">
                                                {!this.state.uploadingSeasonalityData ?
                                                    <div>
                                                        <div className="spinner-border" role="status">

                                                        </div>
                                                        <small>Duke Ngarkuar 'Sezonaliteti '</small>
                                                    </div>
                                                    :
                                                    <p className="uploaded-successfuly">U NGARKUA ME SUKSES <i className="fas fa-check"></i> </p>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 p-2">
                                    <div className="card card-body">
                                        <p className="login-title-uploads">Tregtia</p>
                                        <input type="file" onChange={(value) => { this.selectTrade(value) }} />
                                        <hr />
                                        <button className="btn btn-primary mb-2" onClick={this.uploadTrade} disabled={this.state.trade ? false : true}>Ngarko</button>
                                        <a className="btn btn-primary" href='https://agrodata-ks.com/xlsamples/Tregtia.xlsx' target='_blank'>Shkarko Shembullin</a>

                                        {this.state.uploadingTrade ?
                                            <div className="uploadingHidrometeorology">
                                                {!this.state.uploadingTradeData ?
                                                    <div>
                                                        <div className="spinner-border" role="status">

                                                        </div>
                                                        <small>Duke Ngarkuar 'Tregtia '</small>
                                                    </div>
                                                    :
                                                    <p className="uploaded-successfuly">U NGARKUA ME SUKSES <i className="fas fa-check"></i> </p>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 p-2">
                                    <div className="card card-body">
                                        <p className="login-title-uploads">Aktiviteti Bankar</p>
                                        <input type="file" onChange={(value) => { this.selectBank(value) }} />
                                        <hr />
                                        <button className="btn btn-primary mb-2" onClick={this.uploadBank} disabled={this.state.bank ? false : true}>Ngarko</button>
                                        <a className="btn btn-primary" href='https://agrodata-ks.com/xlsamples/Aktiviteti_bankar.xlsx' target='_blank'>Shkarko Shembullin</a>

                                        {/* uploading prodhimi ne nivel ferme spinner */}

                                        {this.state.uploadingBank ?
                                            <div className="uploadingHidrometeorology">
                                                {!this.state.uploadingBankData ?
                                                    <div>
                                                        <div className="spinner-border" role="status">

                                                        </div>
                                                        <small>Duke Ngarkuar 'Aktiviteti Bankar '</small>
                                                    </div>
                                                    :
                                                    <p className="uploaded-successfuly">U NGARKUA ME SUKSES <i className="fas fa-check"></i> </p>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 p-2">
                                    <div className="card card-body">
                                        <p className="login-title-uploads">Përfituesit bujqësor</p>
                                        <input type="file" onChange={(value) => { this.selectBenefits(value) }} />
                                        <hr />
                                        <button className="btn btn-primary mb-2" onClick={this.uploadBenefits} disabled={this.state.benefits ? false : true}>Ngarko</button>
                                        <a className="btn btn-primary" href='https://agrodata-ks.com/xlsamples/Perfituesit_bujqesor.xlsx' target='_blank'>Shkarko Shembullin</a>

                                        {this.state.uploadingBenefits ?
                                            <div className="uploadingHidrometeorology">
                                                {!this.state.uploadingBenefitsData ?
                                                    <div>
                                                        <div className="spinner-border" role="status">

                                                        </div>
                                                        <small>Duke Ngarkuar 'Përfituesit bujqësor '</small>
                                                    </div>
                                                    :
                                                    <p className="uploaded-successfuly">U NGARKUA ME SUKSES <i className="fas fa-check"></i> </p>
                                                }
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-3 p-2">
                                    <div className="card card-body">
                                        <p className="login-title-uploads">Të Dhënat hidrometeorologjike</p>
                                        <input type="file" onChange={(value) => { this.selectWeather(value) }} />
                                        <hr />
                                        <button className="btn btn-primary mb-2" onClick={this.uploadWeather} disabled={this.state.weather ? false : true}>Ngarko</button> <br />
                                        <a className="btn btn-primary" href='https://agrodata-ks.com/xlsamples/Hidrometeorologji.xlsx' target='_blank'>Shkarko Shembullin</a>
                                    </div>
                                    {this.state.uploadingHidrometeorology ?
                                        <div className="uploadingHidrometeorology">
                                            {!this.state.uploadingHidrometeorologyMessage ?
                                                <div>
                                                    <div className="spinner-border" role="status">

                                                    </div>
                                                    <small>Duke Ngarkuar 'Te Dhënat Hidrometeorologjike'</small>
                                                </div>
                                                :
                                                <p className="uploaded-successfuly">U NGARKUA ME SUKSES <i className="fas fa-check"></i> </p>
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }
}

export default Dashboard;