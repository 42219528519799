import React from 'react';

import './MeteorologicData.scss';

const MeteorologicData = (props) => {
    return(
         props.selectedRegionData ? 
         <div className="row col-12 information__content">

            <div className="name col-12">
                <p className="title">Stacioni meteorologjik</p>
                <p className="name">{ props.selectedRegionData.name }</p>
            </div>
            <div className="details col-12">
                <p className="title">INFORMATA LIDHUR ME LOKACIONIN</p>
                <p className="content geografical__longitude">Gjatësia gjeografike: { props.selectedRegionData.longitude !== null ? props.selectedRegionData.longitude : '' }</p>
                <p className="content geografical__latitude">Gjerësia gjeografike: { props.selectedRegionData.latitude !== null ? props.selectedRegionData.latitude : '' }</p>
                <p className="content sea__level">Lartësia mbidetare: { props.selectedRegionData.seaLevel !== null ? `${props.selectedRegionData.seaLevel}m` : `` }</p>
            </div>
        </div>
        : null
    )
}

export default MeteorologicData;