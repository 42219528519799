import React from 'react';

const Filter = (props) => {
    return(
        <div>
            <div className="production__filter__block">
                <select className="production__filter__block__select" onChange={ (event) => { props.getUpdateData(event)}}>
                    {
                        props.cultures.map((culture, index) => {
                            return <option key={ index } value={ culture._id } data-isbee = { culture.key === 'koshere bletësh' ? true : false }> { culture.culture }</option>
                        })
                    }
                </select>
            </div>
            {/* <div className="production__filter__block">
                <h6 className="filter__block__title">Permasa</h6>
                <div className="production__filter__block_radiobuttons">
                    <form>
                        {
                            props.measures.map((measure, index) => {
                                return (
                                    <div  key={ index } >
                                        <input className="production__filter__block_radiobuttons__input" type="radio" id={"measure"+index} name="measures"/> <label htmlFor={"measure"+index}> { measure } </label><br/>
                                    </div>
                                )
                            })
                        }
                    </form>
                </div>
            </div>
            <div className="production__filter__block">
                <h6 className="filter__block__title">Viti</h6>
                <div className="production__filter__block_radiobuttons">
                    <form>
                        {
                            props.years.map((year, index) => {
                                return (
                                    <div key={ index }>
                                        <input className="production__filter__block_radiobuttons__input" type="radio" name="year" id={"year" + year} /><label htmlFor={"year" + year}>{ year }</label>
                                    </div>
                                )
                            })
                        }
                    </form>
                </div>
            </div> */}
        </div>
    )
}

export default Filter;