import React from 'react';
import './Tables.scss';
import { Bar } from 'react-chartjs-2';


const Tables = (props) => {
    return(
        <div className="row tables">
            <div className="col-lg-12 pt-5">
                <h6 className="tables__title pt-5">BILANCI TREGTAR</h6>
            </div>
            <div className="col-lg-4 p__t">
                <div className="table__single__title">
                    <p>EKSPORT</p>
                </div>
                <table className="table__single">
                    <tbody>
                        <tr>
                            <th className="table_year">VITI</th>
                            <th className="table_amount">SASIA (t)</th>
                            <th className="table_value">VLERA .000 €</th>
                        </tr>
                        {
                            props.exp ?
                                props.exp.map((data, index) => {
                                    return(
                                        <tr key= { index }>
                                            <td className="nr1">{ data.year }</td>
                                            <td className="nr3">{ data.netMassString }</td>
                                            <td className="nr2">{ data.valueString }</td>
                                        </tr>
                                    )
                                })
                                :null    
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-lg-4 p__t tables">
                <div className="table__single__title">
                    <p>IMPORT</p>
                </div>
                <table className="table__single">
                    <tbody>
                        <tr>
                            <th className="table_year">VITI</th>
                            <th className="table_amount">SASIA (t)</th>
                            <th className="table_value">VLERA .000 €</th>
                        </tr>
                        {
                            props.imp ?
                                props.imp.map((data, index) => {
                                    return(
                                        <tr key= { index }>
                                            <td className="nr1">{ data.year }</td>
                                            <td className="nr3">{ data.netMassString }</td>
                                            <td className="nr2">{ data.valueString }</td>
                                        </tr>
                                    )
                                })
                                :null    
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-lg-4 p__t tables">
                <div className="table__single__title">
                    <p>BILANCI TREGTAR</p>
                </div>
                <table className="table__single">
                    <tbody>
                        <tr>
                        <th className="table_year">VITI</th>
                            <th className="table_amount">SASIA (t)</th>
                            <th className="table_value">VLERA .000 €</th>
                        </tr>
                        {
                            props.bil ?
                                props.bil.map((data, index) => {
                                    return(
                                        <tr key= { index }>
                                            <td className="nr1">{ data.year }</td>
                                            <td className="nr3">{ data.netMassString }</td>
                                            <td className="nr2">{ data.valueString }</td>
                                        </tr>
                                    )
                                })
                                :null    
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Tables;