import React, { Component } from 'react';
import Map from './Map/Map';
import Chart from './Chart/Chart';
import Filter from './Filter/Filter';
import Table from './Table/Table';
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import './Benefits.scss';


class Benefits extends Component {

    state = {
        selectedYear: null,
        selectedValue: null,
        years: [],
        parameters: [],
        dataId: null,
        data: [],
        region: 'prishtine',
        cities: {
            prishtine: "Prishtinë",
            mitrovice: "Mitrovicë",
            peje: "Pejë",
            prizren: "Prizren",
            ferizaj: "Ferizaj",
            gjilan: "Gjilan",
            gjakove: "Gjakovë"
        },
        chart: {
            labels: [],
            datasets: [
                { type: 'bar', label: 'Shuma totale (€)', backgroundColor: '#8cc65d', data: [] }
            ]
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                position: 'bottom',
                onClick: (e) => e.stopPropagation()
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        },
        hoverData: [],
        mouseOverData: null
    };

    componentDidMount() {
        // i marrim 5 vitet e fundit
        let startYear = new Date().getFullYear() -1;
        this.setState({ selectedYear: startYear });
        for (let i = 4; i >= 0; i--) {
            this.state.years.unshift(startYear - i);
        }

    }

    componentDidUpdate() {
        //after the componentDidMount is finished we change the state so componentDidUpdate will be triggered and it will execute
        //getUpdateBenefitProvision function wich will make an Api Call(it will happen only once in this component) using state.selectedYear and props.type
        this.getUpdateBenefitProvision();
    }

    getCountryData = (dataId) => {
        Axios.get('https://www.agrodata-ks.com/api/api/benefit/commulator?benefitProvisionId=' + dataId)
            .then((res) => {
                this.setState({ country: res.data })
            })
    }

    getUpdateBenefitProvision() {
        if (this.state.parameters.length == 0) {
            Axios.get('https://www.agrodata-ks.com/api/api/benefitProvision/yearAndType', {
                params: {
                    "year": this.state.selectedYear,
                    "type": this.props.type
                }
            }).then(data => {
                //getData on hover
                this.getCountryData(data.data[0]._id);
                Axios.get('https://www.agrodata-ks.com/api/api/benefit/mapData', {
                    params: {
                        "benefitProvisionId": data.data[0]._id
                    }
                }).then((hovData) => {
                    this.setState({ hoverData: hovData.data });
                })
                //happens only once after component mounts when state parameters is empty array.
                // we get id of the first element which then is selected in dropdown and make another nested call. Same call will be repeated
                // when we change select value(getUpdateData);
                if (data.data.length !== 0) {
                    this.setState({
                        parameters: data.data
                    });
                    //this might be needed when i change year

                    this.getData(data.data[0]._id, this.state.region);
                }
            }).catch(error => { });
        }
    }

    selectedYear = (value) => {

        let chart = {
            labels: [],
            datasets: [
                { type: 'bar', label: 'Shuma totale (€)', backgroundColor: '#8cc65d', data: [] }
            ]
        };

        Axios.get('https://www.agrodata-ks.com/api/api/benefitProvision/yearAndType', {
            params: {
                "year": value,
                "type": this.props.type
            }
        }).then(data => {
            if (data.data.length !== 0) {
                Axios.get('https://www.agrodata-ks.com/api/api/benefit/mapData', {
                    params: {
                        "benefitProvisionId": data.data[0]._id
                    }
                }).then((hovData) => {
                    this.setState({ hoverData: hovData.data });
                })

                this.setState({
                    parameters: data.data,
                    selectedYear: value
                });
                this.getData(data.data[0]._id, this.state.region);
                this.getCountryData(data.data[0]._id);
            }
            else {
                this.setState({ data: [], chart: chart });
            }

        }).catch(error => { });
    }

    // ID e mases
    getUpdateData = (event) => {
        this.getData(event.target.value, this.state.region);
        this.getCountryData(event.target.value);
        //getData on hover
        Axios.get('https://www.agrodata-ks.com/api/api/benefit/mapData', {
            params: {
                "benefitProvisionId": event.target.value
            }
        }).then((hovData) => {
            this.setState({ hoverData: hovData.data });
        })
    }

    getData = (benefitProvisionId, region) => {
        Axios.get('https://www.agrodata-ks.com/api/api/benefit/advancedFilter', {
            params: {
                "benefitProvisionId": benefitProvisionId,
                "region": region
            }
        })
            .then(data => {
                if (data.length !== 0) {
                    this.setState({
                        data: data.data,
                        dataId: benefitProvisionId,
                        region: region,
                        chart: {
                            labels: this.getMunicipalityNames(data.data),
                            datasets: [
                                { type: 'bar', label: 'Shuma totale (€)', backgroundColor: '#8cc65d', data: this.getMunicipalityTotalAmount(data.data) }
                            ]
                        }
                    })
                }
                else {
                    this.setState({
                        chart: {
                            labels: this.getMunicipalityNames(data.data),
                            datasets: [
                                { type: 'bar', label: 'Shuma totale (€)', backgroundColor: '#8cc65d', data: [] }
                            ]
                        }
                    })
                }
            }).catch(error => { });
    };

    getMunicipalityNames = (data) => {
        var array = new Array();
        data.forEach(element => array.push(element.municipality.name));
        return array;
    };

    getMunicipalityTotalAmount = (data) => {
        var array = new Array();
        data.forEach(element => array.push(element.totalAmount));
        return array;
    };

    // selecting region
    regionSelect = (region) => {
        this.getData(this.state.dataId, region);
    }

    onMouseEnter = (val) => {
        this.setState({ mouseOverData: val });
    }

    onMouseLeave = () => {
        this.setState({ mouseOverData: null })
    }

    render() {

        const columns = [{
            accessor: 'municipality.name', // String-based value accessors!
            Header: () => (<section>Komuna e përfituesve <i style={{ transform: 'rotate(90deg)' }} className="fas fa-exchange-alt"></i></section>)
        },
        {
            Header: () => (<section>Numri i përfituesve <i style={{ transform: 'rotate(90deg)' }} className="fas fa-exchange-alt"></i></section>),
            accessor: 'countNumber'
        },
        {
            Header: () => (<section>Shuma totale (€) <i style={{ transform: 'rotate(90deg)', marginLeft: '15px' }} className="fas fa-exchange-alt"></i></section>),
            accessor: 'totalAmountString', // Required because our accessor is not a string
            Cell: props => <span className='grants__table__cell__number'>{<NumberFormat value={props.value} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType='text' />}</span>
        }
        ]

        const columns1 = [{
            accessor: 'year', // String-based value accessors!
            Header: () => (<section>Viti <i style={{ transform: 'rotate(90deg)' }} className="fas fa-exchange-alt"></i></section>)
        },
        {
            Header: () => (<section>Numri i përfituesve <i style={{ transform: 'rotate(90deg)' }} className="fas fa-exchange-alt"></i></section>),
            accessor: 'countNumber'
        },
        {
            Header: () => (<section>Shuma totale (€) <i style={{ transform: 'rotate(90deg)', marginLeft: '15px' }} className="fas fa-exchange-alt"></i></section>),
            accessor: 'totalAmount', // Required because our accessor is not a string
            Cell: props => <span className='grants__table__cell__number'>{<NumberFormat value={props.value} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType='text' />}</span>
        }
        ]

        return (
            <div className="row grants">
                <div className="col-lg-12 pages__content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <h5 className="title">{this.props.title}</h5>
                            </div>
                        </div>
                        <div className="row filters">
                            <div className="col-xl-2 col-lg-4 col-md-6">
                                <Filter
                                    filterTitle={this.props.filterTitle}
                                    parameters={this.state.parameters}
                                    years={this.state.years}
                                    selectedYear={this.selectedYear}
                                    getUpdateData={this.getUpdateData}
                                />
                            </div>

                            {/* MAP */}
                            <div className="col-xl-4 col-lg-8 pt-5 map">
                                <Map
                                    mouseEnter={this.onMouseEnter}
                                    unhoveredRegion={this.onMouseLeave}
                                    hovData={this.state.hoverData}
                                    regionSelect={this.regionSelect}
                                    region={this.state.region}
                                    hoveredRegion={this.state.mouseOverData}
                                />
                            </div>

                            <div className="col-xl-6 col-lg-12 pt-5 grants__table">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table__single__title table__single__title-mt">
                                            <h6>Përfituesit sipas rajonit, {this.state.cities[this.state.region]}</h6>
                                        </div>
                                    </div>
                                    <Table columns={columns} data={this.state.data} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="table__single__title mt-5">
                                <h6>Përfituesit në nivel vendi, Kosovë</h6>
                            </div>
                            <Table columns={columns1} data={this.state.country} />
                        </div>
                        <div className="col-lg-6 pt-5">
                            <div className="row">
                                <div className=" col-12 chart text-center ">
                                    <h6 className="title" style={{ textAlign: 'center ' }}>Shuma Totale e Granteve (eur) Sipas Komunës</h6>
                                    <Chart
                                        data={this.state.chart}
                                        options={this.state.options}
                                    />
                                    {/* <p style={{position:'absolute', transform:'rotate(-90deg)', left: '-60px', top:'180px', fontWeight:'600', color:'#666666', fontSize:'14px'}}>VLERA NE (EUR)</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="alert alert-success mb-5 mt-5" role="alert" style={{ display: "table", fontSize: "14px" }}>
                                <p className="m-0">
                                    <strong>Burimi i të dhënave: </strong>Ministria e Bujqësisë, Pylltarisë dhe Zhvillimit Rural (MBPZHR)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Benefits;