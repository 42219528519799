import React from 'react';

const Filter = (props) => {
    return(
        <div className="p__t chart">
            <div className="tregtia__filter__block">
                <select className="tregtia__filter__block__select" onChange={ (value) => { props.selectName(value)}}>
                    {
                        props.cultures ?
                            props.cultures.map((culture, index) => {
                                return <option key={index} value={ culture._id }> { culture.culture }</option>
                            }): null
                    }
                </select>
            </div>
            <div className="tregtia__filter__block">
                <h6 className="filter__block__title">Viti</h6>
                <div className="tregtia__filter__block__radiobuttons">
                    <form>
                        {
                            props.years.map((year, index) => {
                                return (
                                    <div key={ index }>
                                        <input
                                            id = { 'tregtia' + index } 
                                            className="tregtia__filter__block__radiobuttons__input" 
                                            type="checkbox" 
                                            name="color" 
                                            placeholder ={ year }
                                            onClick={ () => { props.getYear(year)}}
                                            defaultChecked = { props.selectedYear.includes(year) ? true : false}
                                        /> 
                                            <label htmlFor={ 'tregtia' + index }>{ year }</label>
                                    </div> 
                                )
                            })
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Filter;