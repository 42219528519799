import React from 'react';

const Filter = ( props ) => {
    return(
        <div>
            <div className="bank__filter">
                <h6 className="filter__block__title">Aktiviteti</h6>
                <div className="bank__filter__block bank__filter__block--aktiviteti">
                    {/* aktivitetet */}
                        <form>
                            { 
                                props.activities.map((activity, index) => {
                                    return (
                                            <span key={ index }>
                                                <input 
                                                    type="radio" 
                                                    className="filter__block_radiobuttons__input filter__block__radiobuttons__input--activity" 
                                                    id={"bank__rad__but__one" + index} 
                                                    name="banka" 
                                                    onClick = { () => { props.changeActivity(activity.key)} }
                                                    defaultChecked = { props.activity === activity.key ? true:false }
                                                />
                                                <label className="mr-3" htmlFor={"bank__rad__but__one" + index}>{ activity.name }</label>
                                            </span>
                                    )
                                })
                            }
                        </form>
                    {/* aktivitetet */}
                </div>
                <h6 className="filter__block__title pt-3">Viti</h6>
                <div className="bank__filter__block bank__filter__block--viti">         
                    {/* vitet */}
                       
                            <form>
                            { 
                                props.years ? 
                                    props.years.map((year, index) => {
                                        return (
                                            <div key={ index }>
                                                <input type="radio" 
                                                    className="filter__block_radiobuttons__input filter__block__radiobuttons__input--year" 
                                                    id={"bank__rad__but__two" + index} 
                                                    name="banka" onClick={ () => { props.click(year)}}
                                                    defaultChecked = { props.selectedYear == year ? true:false}
                                                />
                                                <label htmlFor={"bank__rad__but__two" + index}>{ year }</label><br/>
                                            </div>
                                        )
                                    })
                                : null
                            }
                        </form>
                    {/* vitet */}
                </div>
                <h6 className="filter__block__title pt-3">Lloji i Kredisë</h6>
                <div className="bank__filter__block bank__filter__block--kredi">
                    {/* lloji i kredis */}
                    <form>
                        {
                            props.loans.map((loan_name, index) => {
                                return (
                                    <div key={ index }>
                                         <input 
                                            type="checkbox" 
                                            className="filter__block_radiobuttons__input filter__block__radiobuttons__input--kredi" 
                                            id= { "bank-loan" + index} 
                                            name="banka"
                                            defaultChecked = { true }
                                            onClick = { () => { props.legendClick(index)}}
                                        />
                                         <label htmlFor= { "bank-loan" + index }>{ loan_name }</label><br/>
                                    </div>
                                )
                            })
                        }
                    </form>
                    {/* lloji i kredis */}
                </div>
            </div>
        </div>
    )
}

export default Filter;