import React, { Component} from 'react';
import Axios from 'axios';
import MeteorologicData from './MeteorologicData/MeteorologicData';
import Filter from './Filter/Filter';
import Map from './Map/Map';
import Chart from './Chart/Chart';
import './Informations.scss';

class Information extends Component {
    state = {
        years: [],
        selectedRegion: 'station1',
        selectedYear: null,
        parameters: ['Temperatura','Lagështia','Rrezatimi','Shiu','Bora'],
        stations: null,
        selectedRegionData: null,
        station_id: null,
        chart: {
            labels: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
            datasets: [
                {hidden: false, type: 'line',label: 'Lagështia (%)',borderColor: 'rgb(0,129,198,0.2)',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                {hidden: false, type: 'line',label: 'Temp Max (C)',borderColor: '#8cc65d',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                {hidden: false, type: 'line',label: 'Temp Min (C)',borderColor: '#0081c6',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                {hidden: false, type: 'bar',label: 'Rrezatimi (mm)',backgroundColor: 'rgb(0,129,198,0.2)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                {hidden: false, type: 'bar',label: 'Shiu (mm)',backgroundColor: '#8cc65d',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                {hidden: false, type: 'bar',label: 'Bora (mm)',backgroundColor: '#0081c6',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
            ]
        },
        options:{
            maintainAspectRatio: false,
            legend: {
                onClick: (e) => e.stopPropagation(),
                position: 'bottom'
            }
        }
    };

    componentDidMount(){
        let startYear = new Date().getFullYear() - 1;
        for(let i = 4; i >= 0; i--){
            this.state.years.unshift( startYear - i);
        }
        this.setState({ selectedYear: startYear });

        let chart =  {
            labels: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
            datasets: [
                { hidden: false, type: 'line',label: 'Lagështia (%)',borderColor: 'rgb(0,129,198,0.2)',backgroundColor: 'rgb(255,255,255,0)',data: []},
                { hidden: false, type: 'line',label: 'Temp Max (C)',borderColor: '#8cc65d',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: false, type: 'line',label: 'Temp Min (C)',borderColor: '#0081c6',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: false, type: 'bar',label: 'Rrezatimi (mm)',backgroundColor: 'rgb(0,129,198,0.2)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: false, type: 'bar',label: 'Shiu (mm)',backgroundColor: '#8cc65d',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: false, type: 'bar',label: 'Bora (mm)',backgroundColor: '#0081c6',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
            ]
        };

        Axios.get('https://www.agrodata-ks.com/api/api/station')
            .then((response) => {
                this.setState({
                   stations: response.data,
                   selectedRegionData: response.data[0],
                   station_id: response.data[0]._id
                });
                Axios.get('https://www.agrodata-ks.com/api/api/hydrometeorologicaldata/filter', {
                    params:{
                        year: startYear,
                        station_id: response.data[0]._id
                    }
                })
                .then(
                    (res) => {
                        for( let i = 0; i <= 12; i++){
                            chart.datasets[0].data[i] = res.data.humidity[i + 1];
                            chart.datasets[1].data[i] = res.data.maxTemp[i + 1];
                            chart.datasets[2].data[i] = res.data.minTemp[i + 1];
                            chart.datasets[3].data[i] = res.data.radiation[i + 1];
                            chart.datasets[4].data[i] =res.data.rain[i + 1];
                            chart.datasets[5].data[i] =res.data.snow[i + 1];
                        }
                        this.setState({chart: chart})
                    }
                )
            });
    }

    clickedStation = (station, station_id) => {
        if(station == 'station1'){
            this.setState({ selectedRegion: station, selectedRegionData: this.state.stations[0], station_id: station_id })
        }
        else if(station == 'station2'){
            this.setState({ selectedRegion: station, selectedRegionData: this.state.stations[1], station_id: station_id })
        }
        else if(station == 'station3'){
            this.setState({ selectedRegion: station, selectedRegionData: this.state.stations[2], station_id: station_id })
        }
        else if(station == 'station4'){
            this.setState({ selectedRegion: station, selectedRegionData: this.state.stations[3], station_id: station_id })
        }
        else if(station == 'station5'){
            this.setState({ selectedRegion: station, selectedRegionData: this.state.stations[4], station_id: station_id })
        }

        let chart =  {
            labels: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
            datasets: [
                { hidden: this.state.chart.datasets[0].hidden, type: 'line',label: 'Lagështia (%)',borderColor: 'rgb(0,129,198,0.2)',backgroundColor: 'rgb(255,255,255,0)',data: []},
                { hidden: this.state.chart.datasets[1].hidden, type: 'line',label: 'Temp Max (C)',borderColor: '#8cc65d',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[2].hidden, type: 'line',label: 'Temp Min (C)',borderColor: '#0081c6',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[3].hidden, type: 'bar',label: 'Rrezatimi (mm)',backgroundColor: 'rgb(0,129,198,0.2)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[4].hidden, type: 'bar',label: 'Shiu (mm)',backgroundColor: '#8cc65d',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[5].hidden, type: 'bar',label: 'Bora (mm)',backgroundColor: '#0081c6',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
            ]
        };

  
        Axios.get('https://www.agrodata-ks.com/api/api/hydrometeorologicaldata/filter', {
            params:{
                year: this.state.selectedYear,
                station_id: station_id
            }
        })
        .then(
            (res) => {
                for( let i = 0; i <= 12; i++){
                    chart.datasets[0].data[i] = res.data.humidity[i + 1];
                    chart.datasets[1].data[i] = res.data.maxTemp[i + 1];
                    chart.datasets[2].data[i] = res.data.minTemp[i + 1];
                    chart.datasets[3].data[i] = res.data.radiation[i + 1];
                    chart.datasets[4].data[i] = res.data.rain[i + 1];
                    chart.datasets[5].data[i] = res.data.snow[i + 1];
                }
                this.setState({chart: chart})
            }
        )
            
    }

    selectYear = ( year ) => {
        this.setState({ selectedYear: year });
        let chart =  {
            labels: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
            datasets: [
                { hidden: this.state.chart.datasets[0].hidden, type: 'line',label: 'Lagështia (%)',borderColor: 'rgb(0,129,198,0.2)',backgroundColor: 'rgb(255,255,255,0)',data: []},
                { hidden: this.state.chart.datasets[1].hidden, type: 'line',label: 'Temp Max (C)',borderColor: '#8cc65d',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[2].hidden, type: 'line',label: 'Temp Min (C)',borderColor: '#0081c6',backgroundColor: 'rgb(255,255,255,0)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[3].hidden, type: 'bar',label: 'Rrezatimi (mm)',backgroundColor: 'rgb(0,129,198,0.2)',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[4].hidden, type: 'bar',label: 'Shiu (mm)',backgroundColor: '#8cc65d',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]},
                { hidden: this.state.chart.datasets[5].hidden, type: 'bar',label: 'Bora (mm)',backgroundColor: '#0081c6',data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
            ]
        };

  
        Axios.get('https://www.agrodata-ks.com/api/api/hydrometeorologicaldata/filter', {
            params:{
                year: year,
                station_id: this.state.station_id
            }
        })
        .then(
            (res) => {
                for( let i = 0; i <= 12; i++){
                    chart.datasets[0].data[i] = res.data.humidity[i + 1];
                    chart.datasets[1].data[i] = res.data.maxTemp[i + 1];
                    chart.datasets[2].data[i] = res.data.minTemp[i + 1];
                    chart.datasets[3].data[i] = res.data.radiation[i + 1];
                    chart.datasets[4].data[i] =res.data.rain[i + 1];
                    chart.datasets[5].data[i] =res.data.snow[i + 1];
                }
                this.setState({chart: chart})
            }
        )

    }

    parameterChange = ( index ) => {
        if(index == 0){
            this.state.chart.datasets[1].hidden = !this.state.chart.datasets[1].hidden;
            this.state.chart.datasets[2].hidden = !this.state.chart.datasets[2].hidden;
        }
        else if( index == 1)
        {
            this.state.chart.datasets[0].hidden = !this.state.chart.datasets[0].hidden;
        }
        else if( index == 2)
        {
            this.state.chart.datasets[3].hidden = !this.state.chart.datasets[3].hidden;
        }
        else if( index == 3)
        {
            this.state.chart.datasets[4].hidden = !this.state.chart.datasets[4].hidden;
        }
        else if( index == 4)
        {
            this.state.chart.datasets[5].hidden = !this.state.chart.datasets[5].hidden;
        }
        this.setState({});
    }
    
    render() {
        return (
            <div className="row">
                <div className="col-lg-12 pages__content information">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <h5 className="title">Të Dhënat Hidrometeorologjike</h5>
                            </div>
                        </div>
                        <div className="row filter">
                            <div className="col-xl-2 col-lg-4">
                                {/* <MeteorologicParameters /> */}
                                <Filter 
                                    years = { this.state.years } 
                                    selectedYear = { this.state.selectedYear }
                                    selectYear = { this.selectYear }
                                    parameters = { this.state.parameters }
                                    parameterChange = { this.parameterChange }
                                />
                            </div>
                            {/* Map */}
                            <div className="col-xl-4 col-lg-8 pt-5 map">
                                <Map 
                                    selectedRegion = { this.state.selectedRegion }
                                    clickStation = { this.clickedStation }
                                    stations = { this.state.stations }
                                />
                            </div>

                            {/* MeteorologicData */}
                            <div className="col-xl-6 col-lg-12">
                                <MeteorologicData 
                                    selectedRegionData = { this.state.selectedRegionData }
                                />
                                {/* <Chart /> */}

                                <div className="row information_chart">
                                    <div className="col-12">
                                        <h5 className="title">Titulli i Grafit</h5>
                                        { this.props.resize ? <p> RAINFALL (mm) </p> : <p style={{position:'absolute', transform:'rotate(-90deg)', left: '-60px', top:'180px', fontWeight:'600', color:'#666666', fontSize:'14px'}}>RAINFALL (mm)</p> }
                                        <Chart 
                                            data = {this.state.chart} 
                                            options = { this.state.options }
                                            resize = { this.props.resize }
                                        />
                                    </div>
                                    {/* Chart */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Information;