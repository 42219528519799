import React from 'react';
import './Navbar.scss';

const navbar = () => {
    const link = 'http://agroportal-ks.com/';
    return (
        <div className="container-fluid navbar-dark">
            <div className="row">
                <div className="col-lg-12">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 p-0 ">
                        <ul className="navbar__list__group">
                            <li className="item">
                                <a className="link" href={link + 'per-ne'}> PËR NE</a>
                            </li>
                            <li className="item">
                                <a className="link" href={ link + 'kontakt'}> KONTAKTI</a>
                            </li>
                            <li className="item">
                                <a className="link" href={ link + 'konspekt'}> KONSPEKT</a>
                            </li>
                            <li className="item">
                                <a className="link" href={ link + 'partneret'}> PARTNERËT</a>
                            </li>
                            <li className="item navbar--socialitem">
                                <a className="link navbar__social__links fb" href="https://www.facebook.com/agroportal.ks/" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a className="link navbar__social__links tw" href="#" target="_blank">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a className="link navbar__social__links inst" href="https://www.instagram.com/agroportal.ks/" target="_blank">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default navbar;