import React from 'react';
import ReactTable from "react-table";
import './Table.scss';

const Table = (props) => {
    return(
        props.data ?  <ReactTable
           multiSort = { false }
           defaultPageSize={props.data ? props.data.length : 0}
           pageSize={props.data ? props.data.length : 0}
           showPagination = { false }
           showPaginationTop = { false }
           data={ props.data }
           columns={ props.columns }
           resizable = { false }
           noDataText = "Mungojnë të dhënat"
     />: null
   )
}

export default Table;