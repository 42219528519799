import React from 'react';

import './Footer.scss';

const Footer = (props) => (
  <footer>
    <div className="row">
      <div className="col-lg-3"><a href="https://agroportal-ks.com/impressum/"><p>Powered by KEP Trust</p></a></div>
      <div className="col-lg-3"><p> ©  COPYRIGHT { new Date().getFullYear() } Recura </p></div>
      <div className="col-lg-3"><a href='https://cacttus.com/harrisia' target='_blank'><p>Developed by HARRISIA</p> </a></div>
      <div className="col-lg-3"><a href="https://agroportal-ks.com/kushtet-e-perdorimit/"><p>Kushtet e përdorimit</p></a></div>
    </div>
  </footer>
);

export default Footer;
