import React, { Component } from 'react';
import Axios from 'axios';

import './Tregtia.scss';
import Chart from './Chart/Chart';
import Filter from './Filter/Filter';
import Tables from './Tables/Tables';

class Tregtia extends Component {
   
    state = {
        years: [],
        selectedYear: [],
        cultures:[],
        culture: null,
        table:[],
        import: null,
        export: null,
        bilanc: null,
        culture: null,
        data: {
            labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ","QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NËNTOR","DHJETOR"],
            datasets: [
                {
                    label: 'EKSPORT',
                    data: [],
                    backgroundColor: [
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'IMPORT',
                    data: [],
                    backgroundColor: [
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',

                    ],
                    borderWidth: 1
                },
                {
                    label: 'BILANCI TREGTAR',
                    data: [],
                    backgroundColor: [
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        

                    ],
                    borderWidth: 1
                }
            ]
        },
        options: {
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            },
            legend:{
                onClick: (e) => e.stopPropagation()
            }
        }
    }
    

    componentDidMount(){  
        const chartObject =  {
            labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ","QERSHOR","KORRIK","GUSHT","SHTATOR","TËTOR","NENTOR","DHJETOR"],
            datasets: [
                {
                    label: 'EKSPORT',
                    data: [],
                    backgroundColor: [
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'IMPORT',
                    data: [],
                    backgroundColor: [
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',

                    ],
                    borderWidth: 1
                },
                {
                    label: 'BILANCI TREGTAR',
                    data: [],
                    backgroundColor: [
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        

                    ],
                    borderWidth: 1
                }
            ]
        }
        // get last 5 years
        let startYear = new Date().getFullYear() ;
        for(let i = 4; i >= 0; i--){
            this.state.years.unshift( startYear - i);
        }
        const years = [...this.state.selectedYear ];
        years.push(startYear);
        this.setState({ selectedYear: years });

        Axios.get('https://www.agrodata-ks.com/api/api/trade')
            .then( response => {
                this.setState({ cultures: response.data, culture: response.data[0]._id })
                Axios.get('https://www.agrodata-ks.com/api/api/tradeData', {
                    params:{
                        "trade_id": response.data[0]._id,
                        "years": this.state.selectedYear.join(',')
                    }
                })
                    .then((response) => {

                        for(let i = 0; i< 12; i++){
                            chartObject.datasets[0].data.push(response.data.chartTable.exp[i].value);
                        }
                       
                        for(let i = 0; i< 12; i++){
                            chartObject.datasets[1].data.push(response.data.chartTable.imp[i].value);
                        }
                        
                        for(let i = 0; i< 12; i++){
                            chartObject.datasets[2].data.push(response.data.chartTable.bil[i].value);
                        }

                        this.setState({
                            data: chartObject,
                            import: response.data.imp,
                            export: response.data.exp,
                            bilanc: response.data.bil
                        });
                    })
            })

            console.log('selectedYear',this.state.selectedYear);
            console.log('years',this.state.years);
            console.log('year',this.state.year);
      
    }

    
    getYear = ( year ) => {
        const chartObject =  {
            labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ","QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NËNTOR","DHJETOR"],
            datasets: [
                {
                    label: 'EKSPORT',
                    data: [],
                    backgroundColor: [
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'IMPORT',
                    data: [],
                    backgroundColor: [
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',

                    ],
                    borderWidth: 1
                },
                {
                    label: 'BILANCI TREGTAR',
                    data: [],
                    backgroundColor: [
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        

                    ],
                    borderWidth: 1
                }
            ]
        }

        let years = [...this.state.selectedYear ];
        let foundIndex = years.findIndex((selYear) => {
            return selYear == year;
        })

        if(foundIndex == -1 ){
            years.push(year);
            this.setState({ selectedYear: years })
        }
        else
        {
            const filteredArray = years.filter((selYear) => {
                return selYear !== year;
            })

            years = filteredArray;

            this.setState({ selectedYear: years })
        }

        Axios.get('https://www.agrodata-ks.com/api/api/tradeData', {
            params:{
                "trade_id": this.state.culture,
                "years": years.join(',')
            }
        })
            .then((response) => {

                for(let i = 0; i< 12; i++){
                    chartObject.datasets[0].data.push(response.data.chartTable.exp[i].value);
                }
                
                for(let i = 0; i< 12; i++){
                    chartObject.datasets[1].data.push(response.data.chartTable.imp[i].value);
                }
                
                for(let i = 0; i< 12; i++){
                    chartObject.datasets[2].data.push(response.data.chartTable.bil[i].value);
                }

                this.setState({
                    data: chartObject,
                    import: response.data.imp,
                    export: response.data.exp,
                    bilanc: response.data.bil
                });
            })
            console.log('import',this.state.import);
            console.log('export',this.state.export);
            console.log('bilanc',this.state.bilanc);
    }


    selName = (event) => {
        let culture = event.target.value;
        this.setState({ culture: culture});

        const chartObject =  {
            labels: ["JANAR", "SHKURT", "MARS", "PRILL", "MAJ","QERSHOR","KORRIK","GUSHT","SHTATOR","TETOR","NËNTOR","DHJETOR"],
            datasets: [
                {
                    label: 'EKSPORT',
                    data: [],
                    backgroundColor: [
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                        '#8cc65d',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'IMPORT',
                    data: [],
                    backgroundColor: [
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',
                        '#0081c6',

                    ],
                    borderWidth: 1
                },
                {
                    label: 'BILANCI TREGTAR',
                    data: [],
                    backgroundColor: [
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        '#f68426',
                        

                    ],
                    borderWidth: 1
                }
            ]
        }

      
        Axios.get('https://www.agrodata-ks.com/api/api/tradeData', {
            params:{
                "trade_id": culture,
                "years": this.state.selectedYear.join(',')
            }
        })
        .then((response) => {

            for(let i = 0; i< 12; i++){
                chartObject.datasets[0].data.push(response.data.chartTable.exp[i].value);
            }
            
            for(let i = 0; i< 12; i++){
                chartObject.datasets[1].data.push(response.data.chartTable.imp[i].value);
            }
            
            for(let i = 0; i< 12; i++){
                chartObject.datasets[2].data.push(response.data.chartTable.bil[i].value);
            }

            this.setState({
                data: chartObject,
                import: response.data.imp,
                export: response.data.exp,
                bilanc: response.data.bil
            });
        })
            
    }
    
    render(){
        return(
           <div className="col-lg-12 pages__content">
                <div className="row">
                    <div className="col-lg-4 tregtia__filter">
                        <h5 className="tregtia__filter__title">TREGTIA</h5>
                        <Filter 
                            years={ this.state.years }
                            selectedYear = { this.state.selectedYear}
                            getYear = { this.getYear }
                            cultures = { this.state.cultures }
                            selectName = { this.selName }
                        />
                    </div>
                    <div className="col-lg-8 pt-5 chart">
                        <Chart 
                            data = { this.state.data}
                            options = { this.state.options } 
                        />
                    </div>
                </div>
                <Tables
                    bil = { this.state.bilanc }
                    imp = { this.state.import }
                    exp = { this.state.export }
                />
                <div className="col-lg-6 p-0">
                    <div className="alert alert-success mb-5" role="alert" style={{display: "table", fontSize: "14px"}}>
                        <p className="m-0">
                            <strong>Burimi i të dhënave: </strong>Dogana e Kosovës
                        </p>
                    </div>
                </div>
           </div>
        )
    }
}

export default Tregtia;