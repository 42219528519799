import React from 'react';

import './Filter.scss';

const Filter = (props) => {
    return(
        <div>
            <div className="general__filter">
                <h6 className="title">Parametrat</h6>
                <div className="radiobuttons">
                    <form>
                        <ul className="ul">
                            { props.parameters.map((value, index) => {
                                return <li 
                                            key={index} 
                                            className="col-lg-12 col-6">
                                            <input id={'grant__filter' + index } 
                                            className="input filter__block__radiobuttons__input" 
                                            type="checkbox" 
                                            name="year"
                                            onClick = { () => { props.parameterChange(index)}}
                                            defaultChecked = { true }
                                            />
                                            <label htmlFor={'grant__filter' + index }>{ value }</label>
                                        </li>;
                            })}
                        </ul>
                    </form>
                </div>
            </div>
            <div className="general__filter">
                <h6 className="title">Viti</h6>
                <div className="radiobuttons">
                    <form>
                        { props.years ? 
                            <ul className="ul">
                            { props.years.map((value, index) => {
                                return <li key={index} className="col-lg-12 col-6">
                                            <input 
                                                id={'grant__year__filter' + index } 
                                                className="input filter__block__radiobuttons__input" 
                                                type="radio" name="year" 
                                                defaultChecked = { props.selectedYear == value ? true: false } 
                                                onClick = { () => { props.selectYear(value)} }/>
                                            <label htmlFor={'grant__year__filter' + index }>{ value }</label>
                                        </li>;
                            })}
                            </ul>: null
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Filter;