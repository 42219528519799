import React, { Component } from 'react';
import './Production.scss';
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import Filter from './Filter/Filter';
import Map from './Map/Map';
import Table from './Table/Table';


class Production extends Component {
    state = {
        city: 'prishtine',
        cities: {
            prishtine: "Prishtinë",
            mitrovice: "Mitrovicë",
            peje: "Pejë",
            prizren: "Prizren",
            ferizaj: "Ferizaj",
            gjilan: "Gjilan",
            gjakove: "Gjakovë"
        },
        production_id: null,
        cultures:[],
        measures:[],
        measuresCopy:[],
        years:[],
        table:[],
        country: [],
        mouseOverData: 'prishtine'
    }

    componentDidMount(){
        // masat
        Axios.get('https://www.agrodata-ks.com/api/api/measure/classification?classification=' + this.props.category )
        .then(
            (response) => {
                this.setState({ measures: response.data, measuresCopy: response.data });
            }
        )
        // kulturat
        Axios.get('https://www.agrodata-ks.com/api/api/production?classification=' + this.props.category )
            .then(
                (response) => {
                    if(response.data.length > 0){
                        if(response.data[0].key === 'koshere bletësh') {
                            let newdata = [...this.state.measuresCopy];
                            for(let i=0; i<newdata.length; i++) {
                                if(newdata[i].variableName === 'animalsNumber')
                                    newdata.splice(i, 1);
                            }
                
                            this.setState({ measures: newdata });
                        }
                        else {
                            let newdata = [...this.state.measuresCopy];
                            let i = newdata.length;
                
                            for(let i=0; i<newdata.length; i++) {
                                if(newdata[i].variableName === 'beesNumber')
                                    newdata.splice(i, 1);
                            }
                
                            this.setState({ measures: newdata });
                        }

                        this.setState({ cultures: response.data, production_id: response.data[0]._id });
                        Axios.get('https://www.agrodata-ks.com/api/api/data/filter?', {
                            params: {
                                "region": this.state.city,
                                "production_id": response.data[0]._id
                            }
                        })
                        .then((response) => {
                            this.setState({ table: response.data });
                        });

                        Axios.get('https://www.agrodata-ks.com/api/api/data/commulator?production_id=' + response.data[0]._id)
                            .then(res => {
                                this.setState({ country: res.data });
                            });
                    }
                }
            );
    }

    getUpdateData = ( event ) => {
        if(event.target[event.target.selectedIndex].getAttribute('data-isbee') === 'true') {
            let newdata = [...this.state.measuresCopy];
            for(let i=0; i<newdata.length; i++) {
                if(newdata[i].variableName === 'animalsNumber')
                    newdata.splice(i, 1);
            }

            this.setState({ measures: newdata });
        }
        else {
            let newdata = [...this.state.measuresCopy];
            let i = newdata.length;

            for(let i=0; i<newdata.length; i++) {
                if(newdata[i].variableName === 'beesNumber')
                    newdata.splice(i, 1);
            }

            this.setState({ measures: newdata });
        }
        let id = event.target.value;
        Axios.get('https://www.agrodata-ks.com/api/api/data/commulator?production_id=' + id)
            .then(res => {
                this.setState({ country: res.data });
            });
        Axios.get('https://www.agrodata-ks.com/api/api/production?classification=' + this.props.category )
            .then(
                (response) => { 
                    if(response.data.length > 0){
                        this.setState({ production_id: id });
                        Axios.get('https://www.agrodata-ks.com/api/api/data/filter?', {
                            params: {
                                "region": this.state.city,
                                "production_id": id
                            }
                        })
                        .then((response) => {
                            this.setState({ table: response.data });
                        })
                    }
                }
            )
    }

    
    cityClicked = (event) => {
        this.setState({ city: event });
        Axios.get('https://www.agrodata-ks.com/api/api/production?classification=' + this.props.category )
            .then(
                (response) => { 
                    if(response.data.length > 0){
                        Axios.get('https://www.agrodata-ks.com/api/api/data/filter?', {
                            params: {
                                "region": event,
                                "production_id": this.state.production_id
                            }
                        })
                        .then((response) => {
                            this.setState({ table: response.data });
                        })
                    }
                }
            )
    }

    mouseEnter = (val) => {
        this.setState({ mouseOverData: val });
    }

    unhoverData = () => {
        this.setState({ mouseOverData: ''});
    }
    
    render(){  
        const test = this.state.measures.map((measure, key) => {
            let variables = ['beesNumber', 'animalsNumber'];
            return(
               {
                    accessor: measure.variableName, // String-based value accessors!
                    Header: () => (<section> { measure.name }  <i style={{transform:'rotate(90deg)'}} className="fas fa-exchange-alt"></i></section>),
                    Cell: props => <span>{ <NumberFormat value={props.value} thousandSeparator={true} decimalScale={variables.includes(measure.variableName) ? 0 : 2} fixedDecimalScale={true} decimalScale={2} displayType='text' /> }</span>
               }
            )
        })
        const columns = [
            {
              width: 70,
              accessor: 'year', // String-based value accessors!
              Header: () => (<section>VITI  <i style={{transform:'rotate(90deg)'}} className="fas fa-exchange-alt"></i></section>)
            },
            ...test
        ]
            // {
            //   accessor: 'direct_production', // String-based value accessors!
            //   Header: () => (<section>PRODHIMI DIREKT <span style={{color: '#f68426'}}>(ton)</span> <i style={{transform:'rotate(90deg)'}} className="fas fa-exchange-alt"></i></section>)
            // }, 
            // {
            //   Header: () => (<section>SIPERFAQE <span style={{color: '#f68426'}}>(ha)</span> <i style={{transform:'rotate(90deg)'}} className="fas fa-exchange-alt"></i></section>),
            //   accessor: 'square'
            // },
            // {
            //   Header: () => (<section>REDIMENTI <span style={{color: '#f68426'}}></span> <i style={{transform:'rotate(90deg)'}} className="fas fa-exchange-alt"></i></section>),
            //   accessor: 'rediment', // Required because our accessor is not a string
            // }
          
        return(
            <div className="col-lg-12 pages__content production">
                 <div className="row">
                    <div className="col-lg-12">
                        <h5 className="production__title">{ this.props.tempTitle }</h5>
                    </div>
                </div>
                <div className="row production__filter p__t">
                    <div className="col-xl-2 col-lg-4 col-md-6">
                        <Filter
                            cultures = { this.state.cultures }
                            getUpdateData = { this.getUpdateData }
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xl-6 col-md-8 p__t text-center map">
                                <Map
                                    cityClicked = { this.cityClicked }
                                    city = { this.state.city }
                                    mouseEnter = { this.mouseEnter }
                                    hoveredRegion = { this.state.mouseOverData }
                                    region = { this.state.city }
                                    unhoveredRegion = { this.unhoverData }
                                />
                            </div>
                            <div className="col-xl-6 col-lg-12 p__t  production__table">
                                <div className="table__single__title table__single__title-mt">
                                    <h6>Prodhimi sipas rajonit, { this.state.cities[this.state.city] }</h6>
                                </div>
                                { this.state.measures ? <Table
                                    columns = { columns } 
                                    data = { this.state.table }
                                />: null }
                                
                                <div className="table__single__title mt-5">
                                    <h6>Prodhimi në nivel vendi, Kosovë</h6>
                                </div>
                                { this.state.measures ? <Table
                                    columns = { columns } 
                                    data = { this.state.country }
                                />: null }
                            </div>
                            <div className="col-xl-5 col-lg-12 p__t  production__table">
                                 <div className="alert alert-success mb-5" role="alert" style={{display: 'table', fontSize: "14px"}}>
                                    <p className="m-0">
                                     <strong>Burimi i të dhënave: </strong>Regjistrimi i Bujqësisë 2014, ASK; Raporti i Gjelbër 2018, MBPZHR; të përllogaritura në bazë të indeksit të pjesëmarrjes sipas komunave dhe kulturave
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Production;