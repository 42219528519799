import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Footer from './components/PageComponents/Footer/Footer';
import Navbar from './components/PageComponents/Navbar/Navbar';
import Advertisement from './components/PageComponents/Advertisement/Advertisement';
import Sidebar from './components/PageComponents/Sidebar/Sidebar';
import Informations from './containers/Informations/Informations';
import Benefits from './containers/Benefits/Benefits';
import Bank from './containers/Bank/Bank';
import Seasonality from './containers/Seasonality/Seasonality';
import Tregtia from './containers/Tregtia/Tregtia';
import Production from './containers/Production/Production';
import Dashboard from './containers/Dashboard/Dashboard';
import DataSource from './containers/DataSource/DataSource';
import Table from './containers/table';

class App extends Component {
 
  render() {
    return (
      <div className="container-fluid">
        {/* <div className="row">
          <div className="col-lg-12 p-0">
            <Navbar />
          </div>
        </div> */}
        <div className="row logo_ad_nav">
          <div className="col-lg-12 p-0">
            <Advertisement/>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-lg-12 col-md-12 p-0">
            <Sidebar />
          </div>
          <div className="col-xl-10 col-lg-12 col-md-12 pages__content">
            <Switch>
              <Redirect from="/" exact to="/production-peme"/>
              <Route path="/aktiviteti-bankar" component={ () => <Bank/> }/>
              <Route path="/information" component = { () => <Informations /> }/>
              {/* <Route path="/datasource" component = { DataSource } /> */}
              
              <Route path="/perfituesit-bujqesor-grantet" component={ () => <Benefits filterTitle="Masat" type="Grant" title="Grantet" /> } />
              <Route path="/perfituesit-bujqesor-subvencionet" component={ () => <Benefits filterTitle="Sektorët" type="Subvencion" title="Subvencionet"/> } />

              <Route path="/sezonaliteti-peme" component = { () => <Seasonality category="Pemë" title="Pemë" /> } />
              <Route path="/sezonaliteti-perime" component = { () => <Seasonality category="Perime" title="Perime" /> } />
              <Route path="/sezonaliteti-vreshta" component = { () => <Seasonality category="Vreshta" title="Vreshta" /> } />
              <Route path="/sezonaliteti-drithera" component = { () => <Seasonality category="Drithëra" title="Drithëra" /> } />
              <Route path="/sezonaliteti-tjera" component = { () => <Seasonality category="Tjera" title="Të Tjera" /> } />

              <Route path="/tregtia" component = {() => <Tregtia/>} />
              <Route path="/production-peme" component = { () => <Production tempTitle="PEMË" category="pemë" title="pemetaria"/>} />
              <Route path="/production-perime" component = { () => <Production tempTitle="PERIME" category="perime" title="perimtaria"/>} />
              <Route path="/production-vreshta" component = { () => <Production tempTitle="VRESHTA" category="vreshta" title="vreshtaria"/>} />
              <Route path="/production-drithera" component = { () => <Production tempTitle="DRITHËRA" category="drithëra" title="drithera"/>} />
              <Route path="/production-blegtoria" component = { () => <Production tempTitle="BLEGTORIA" category="blegtoria" title="blegtoria"/>} />
              <Route path="/production-tjera" component = { () => <Production tempTitle="TË TJERA" category="tjera" title="tjera"/>} />
              <Route path="/dashboard-934-tesla-1" render = { () => <Dashboard/> } />
            </Switch>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center p-0">
            <Footer/>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
